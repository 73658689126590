




































import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class Login extends Vue {
  // Data
  username = "";
  password = "";
  valid = false;
  rules = {
    username: [(v: String) => !!v || "Username is required"],
    password: [(v: String) => !!v || "Password is required"]
  };
  wrongCredentials = false; // sets appropriate message if true

  // Methods
  loginUser() {
    this.$store
      .dispatch("loginUser", {
        username: this.username,
        password: this.password
      })
      .then(() => {
        this.wrongCredentials = false;
        this.$router.push({ name: "home" });
      })
      .catch(() => {
        this.wrongCredentials = true;
      });
  }
}
